import * as React from 'react';
import { useContext, useState } from 'react';
import ProjectList from './ProjectList';
import ProjectDetail from './ProjectDetail';
import { Box, Grid, Stack } from '@mui/material';
import { ViewContext } from '../ViewContext';

export default function ProjectPane() {
  const { activeProjectId, setActiveProjectId, activeArtifactId, setActiveArtifactId } = useContext(ViewContext);

  const handleSelectProject = (id) => {
    console.log('Selected project:', id);
    setActiveProjectId(id);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'white' : theme.palette.grey[900]),
          backgroundImage: 'url(bg.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: '100px',
          overflowY: 'hidden',
          flexGrow: 1,
          width: '100%',
          padding: (theme) => theme.spacing(3),
        }}
      >
        {activeProjectId === null && <ProjectList onSelectProject={handleSelectProject} />}
        {activeProjectId != null && <ProjectDetail projectId={activeProjectId} />}
      </Box>
    </>
  );
}
