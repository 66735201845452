import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {
  Autocomplete,
  Backdrop,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  SliderValueLabel,
  TextField,
  Toolbar,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import * as DefaultPrompts from '../defaultPrompts';
import BlockDisplay from './BlockDisplay';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChevronLeft, Delete, Download, Edit, MoreVert } from '@mui/icons-material';
import { ViewContext } from '../ViewContext';
import { personas_gitlab } from '../data/personas_gitlab';
import ArtifactAssetTable from './ArtifactAssetTable';
import AssetUploadDialog from './AssetUploadDialog';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ArtifactGuidePane from './ArtifactGuidePane';
import { useAuth0 } from '@auth0/auth0-react';
import RegenerateDialog from './RegenerateDialog';
import ArtifactVersionList from './ArtifactVersionList';
import VariantGuidePane from './VariantGuidePane';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const PersonaSelector = () => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">Persona</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Persona" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {personas_gitlab.map((persona) => (
            <MenuItem key={persona.name} value={persona.name} style={getStyles(persona.name, personName, theme)}>
              {persona.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default function ArtifactDetail({ artifactId }) {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [artifact, setArtifact] = useState(null);
  const [artifactHistory, setArtifactHistory] = useState([]);
  const { activeProjectId, setActiveProjectId, activeArtifactId, setActiveArtifactId } = useContext(ViewContext);
  const [project, setProject] = useState(null);

  const [regenerateInstructions, setRegenerateInstructions] = React.useState('');

  const [topic, setTopic] = useState('');
  const [industry, setIndustry] = useState('');
  const [readerRole, setReaderRole] = useState('');
  const [level, setLevel] = useState('');
  const [generating, setGenerating] = useState(false);
  const [generatingAll, setGeneratingAll] = React.useState(false);
  const pollInterval = React.useRef(null);
  const [generatedBlocks, setGeneratedBlocks] = useState([]);
  const [assetUploadDialogOpen, setAssetUploadDialogOpen] = React.useState(false);

  const [expandedPanel, setExpandedPanel] = React.useState('panel1');
  const [selectedAssetIds, setSelectedAssetIds] = React.useState([]);

  const openAssetsDialog = (e) => {
    e.preventDefault();
    setAssetUploadDialogOpen(true);
  };

  const closeAssetsDialog = (e) => {
    setAssetUploadDialogOpen(false);
    fetchProject(activeProjectId);
  };

  const refreshAssets = () => {
    fetchProject(activeProjectId);
  };

  const fetchProject = async (projectId) => {
    if (!projectId) {
      console.error('No project ID provided.');
      return;
    }

    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };

    await axios
      .get(url, { headers: headers })
      .then((response) => response.data)
      .then((data) => setProject(data));
  };

  const fetchArtifact = async () => {
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}`;
    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };

    await axios
      .get(url, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        setArtifact(data);
        if (data.generation_state === 'GENERATE_COMPLETE') {
          stopPollingArtifact();
        }
      })
      .catch((error) => {
        stopPollingArtifact();
        enqueueSnackbar('Error fetching artifact', { variant: 'error' });
        console.error('Error fetching artifact:', error);
      });
  };

  const fetchArtifactHistory = async (artifactId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/history`);
    const data = await response.json();
    setArtifactHistory(data);
    console.log('Artifact History: ', data);
  };

  const [loadedSavedPrompts, setLoadedSavedPrompts] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    fetchProject(activeProjectId);
    fetchArtifact(artifactId);
  }, [artifactId, assetUploadDialogOpen]);

  useEffect(() => {
    const loadSavedPrompts = () => {
      if (loadedSavedPrompts) {
        console.log('Prompts already loaded');
        return;
      }

      if (!artifact || !artifact.frontEndPrompts) {
        console.log('No frontEnd prompts');
        return;
      }

      console.log('Setting fep: ', artifact.frontEndPrompts);
      const p = artifact.frontEndPrompts;
      if (p.topic) setTopic(p.topic);
      if (p.industry) setIndustry(p.industry);
      if (p.readerRole) setReaderRole(p.readerRole);
      if (p.level) setLevel(p.level);
      if (p.segment) setSegment(p.segment);
      if (p.product) setProduct(p.product);
      if (p.selectedAssetIds) setSelectedAssetIds(p.selectedAssetIds);
      //if (p.personas) restorePersonaList(p.personas);
      setLoadedSavedPrompts(true);
    };

    loadSavedPrompts();
  }, [artifact]);

  useEffect(() => {
    fetchArtifactHistory(artifactId);
  }, [artifactId]);

  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  useEffect(() => {
    const updateButtonsDisabled = () => {
      if (topic.trim().length > 0 && industry && industry.trim().length > 0) {
        setButtonsDisabled(false);
      } else {
        setButtonsDisabled(true);
      }
    };
    updateButtonsDisabled();
  }, [topic, industry]);

  const levelPrompts = {
    100: 'Beginner: This audience is new to the topic and likely has little to no prior knowledge of the subject matter. Content at this level focuses on providing fundamental concepts, definitions, and simple explanations. The goal is to introduce the topic in a straightforward and accessible way.',
    200: "Intermediate: These readers have some foundational understanding and are ready to dive deeper. Content here builds on the basics, introducing more detailed explanations, practical examples, and use cases. It aims to enhance the reader's understanding and provide insights that bridge the gap between beginner and more advanced concepts.",
    300: 'Advanced: This audience has a solid understanding of the subject and seeks to explore complex topics. Content at this level includes in-depth technical details, advanced methodologies, and problem-solving techniques. It is tailored for professionals looking to deepen their expertise and apply knowledge to real-world scenarios.',
    400: "Expert: These readers are experts or specialists in the field who require highly technical and specialized content. This level involves comprehensive analysis, cutting-edge research, and detailed discussions on innovative practices. Content is designed to challenge the reader's knowledge and encourage thought leadership within the industry.",
  };

  const constructTOCPrompt = () => {
    var prompts = {};

    prompts.project = project.project_prompt;
    prompts.toc = DefaultPrompts.defaultTOCPrompt;
    prompts.topic = `The topic to write about is: ${topic}\n`;

    if (readerRole && readerRole.trim().length > 0) {
      prompts.role = `Write it for this role: ${readerRole}`;
    }

    prompts.audience = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    prompts.level = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    if (industry && industry.trim().length > 0) {
      prompts.industry = `Write it for readers who work in this industry: ${industry}`;
    }
    return prompts;
  };

  const constructFullPrompt = () => {
    var prompts = {};
    prompts.project = project.project_prompt;
    // prompts.push(project.artifact_types[0].content_prompt);
    prompts.topic = `The topic to write about is: ${topic}\n`;
    if (readerRole && readerRole.trim().length > 0) {
      prompts.role = `Write it for this role: ${readerRole}`;
    }

    prompts.audience = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    prompts.level = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    if (industry && industry.trim().length > 0) {
      prompts.industry = `Write it for readers who work in this industry: ${industry}`;
    }
    return prompts;
  };

  const constructRegeneratePrompt = () => {
    var prompts = {};
    prompts.project = project.project_prompt;
    prompts.content_prompt = regenerateInstructions;
    prompts.topic = `The topic to write about is: ${topic}\n`;
    if (readerRole && readerRole.trim().length > 0) {
      prompts.role = `Write it for this role: ${readerRole}`;
    }

    prompts.audience = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    prompts.level = `Write it for this level of audience:\n ${levelPrompts[level]}`;

    if (industry && industry.trim().length > 0) {
      prompts.industry = `Write it for readers who work in this industry: ${industry}`;
    }

    return prompts;
  };

  const getTopicPrompt = () => {
    prompt = `
        The topic of the document is ${topic}.
        
        `;
    return prompt;
  };

  const getAudiencePrompt = () => {
    prompt = `
        Write the document for a ${readerRole} in the ${industry} industry.  The audience level is ${level}: ${levelPrompts[level]}
        `;

    return prompt;
  };

  const getFrontEndPrompts = () => {
    var data = {
      topic: topic,
      industry: industry,
      level: level,
      readerRole: readerRole,
      segment: segment,
      product: product,
      selectedAssetIds: selectedAssetIds,
      //personas: personas.map(p => p.name).join('*|*')
      //personas: "persona names here"
    };

    return data;
  };

  const onGuideGenerate = async () => {
    console.log('onGuideGenerate');
    if (artifact.type.is_multishot) {
      await generateTOC();
      await generateAllChapters();
    } else {
      await generateTOC();
      await fetchArtifact();
    }
  };

  const generateTOC = async () => {
    console.log('Generating TOC');
    setGenerating(true);

    const requestData = {
      prompts: { topic_prompt: getTopicPrompt(), audience_prompt: getAudiencePrompt() },
      asset_ids: selectedAssetIds,
      project_id: activeProjectId,
      frontEndPrompts: getFrontEndPrompts(),
    };
    console.log('generateTOC RequestData: ', requestData);

    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/generate_toc`;

    await axios
      .post(url, requestData, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        enqueueSnackbar('Failed to generate TOC. Please try again.', { variant: 'error' });
        console.error('Error:', error);
      })
      .finally(() => {
        setGenerating(false);
      });
  };

  const [regenDialogOpen, setRegenDialogOpen] = React.useState(false);
  const [blockIdToRegenerate, setBlockIdToRegenerate] = React.useState(null);
  const [blockContentForRegen, setBlockContentForRegen] = React.useState('');

  const openRegenDialog = (block) => {
    console.log('Open regen dialog for block: ', block);
    setBlockIdToRegenerate(block.block_id);
    setBlockContentForRegen(block.content);
    setRegenDialogOpen(true);
  };

  const regenerateBlock = async () => {
    setGenerating(true);
    console.log('Regenerating block: ', blockIdToRegenerate);

    const prompts = {
      instructions: `Update the content of this section and return it as a single updated section.  Follow these instructions: ${regenerateInstructions}`,
      original_content: `\n\n\n\nThe original text is: \n\n${blockContentForRegen}`,
    };

    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/regenerate_block/${blockIdToRegenerate}`;

    try {
      await axios
        .post(url, { prompts: prompts }, { headers: headers })
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          fetchArtifact();
        })
        .catch((error) => {
          enqueueSnackbar(`Error regenerating block: ${blockIdToRegenerate}.  ${error.message}`, { variant: 'error' });
          console.error(`Error regenerating block: ${blockIdToRegenerate}.  ${error.message}`);
        })
        .finally(() => {
          setGenerating(false);
        });
    } finally {
      setRegenDialogOpen(false);
    }
  };

  async function generateChapterText(block_id) {
    setGenerating(true);
    console.log('Generating chapter text for block: ', block_id);
    const block = artifact.blocks.find((b) => b.block_id === block_id);

    const prompts = [DefaultPrompts.defaultChapterPrompt + block.content];
    const requestData = { prompts: prompts };

    await fetch(`${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/regenerate_block/${block_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetchArtifact();
      })
      .finally(() => {
        setGenerating(false);
      });
  }

  const startPollingArtifact = () => {
    setGeneratingAll(true);
    pollInterval.current = setInterval(() => {
      fetchArtifact();
    }, 2000);
    console.log('Started polling for' + pollInterval.current);
  };

  const stopPollingArtifact = () => {
    console.log('Stopping polling for ' + pollInterval.current);
    clearInterval(pollInterval.current);
    setGeneratingAll(false);
  };

  async function generateAllChapters() {
    console.log('Generating multishot document');
    startPollingArtifact();
    const requestData = {
      prompts: { topic_prompt: getTopicPrompt(), audience_prompt: getAudiencePrompt() },
      asset_ids: selectedAssetIds,
      project_id: activeProjectId,
      frontEndPrompts: getFrontEndPrompts(),
    };

    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/generate_all`;
    await axios
      .post(url, requestData, { headers: headers })
      .then((data) => {
        console.log(data);
        fetchArtifact();
      })
      .catch((error) => {
        enqueueSnackbar('Failed to generate document.', { variant: 'error' });
        console.error('Error:', error);
      })
      .finally(() => {
        setGenerating(false);
      });
  }

  async function regenerateAll() {
    startPollingArtifact();
    const prompts = constructRegeneratePrompt();
    const topic_prompt = getTopicPrompt + '\n' + regenerateInstructions;
    const requestData = {
      prompts: { topic_prompt: topic_prompt, audience_prompt: getAudiencePrompt() },
      asset_ids: selectedAssetIds,
      project_id: activeProjectId,
      frontEndPrompts: getFrontEndPrompts(),
    };

    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/generate_all`;
    await axios
      .post(url, requestData, { headers: headers })
      .then((data) => {
        console.log(data);
        fetchArtifact();
      })
      .catch((error) => {
        enqueueSnackbar('Failed to regenerate document.', { variant: 'error' });
        console.error('Error:', error);
      })
      .finally(() => {
        setGenerating(false);
      });
  }

  const downloadFile = async (format) => {
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/docify?format=${format}`;
    const accessToken = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${accessToken}` };

    await fetch(`${url}`, { headers: headers })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `document.${format}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        enqueueSnackbar(`Error downloading file:.  ${error.message}`, { variant: 'error' });
        console.error(`Error regenerating block:.  ${error.message}`);
        return Promise.reject({ Error: 'Something Went Wrong', error });
      });
  };

  const [randomProgressPhrase, setRandomProgressPhrase] = React.useState('');

  const getProgressPhrase = () => {
    const phrases = [
      'Brewing up brilliance! ☕',
      'Our LLM is on the case! 🕵️‍♂️',
      'Turning ideas into gold… ✍️',
      'Crafting your marketing magic… ✨',
      'Elevating your strategy to new heights… ⛰️',
    ];
    const idx = Math.floor(Math.random() * (phrases.length + 1));
    return phrases[idx];
  };

  useEffect(() => {
    setRandomProgressPhrase(getProgressPhrase());
  }, [generating]);

  const [artifactVersionListOpen, setArtifactVersionListOpen] = React.useState(false);
  const [addVariantOpen, setAddVariantOpen] = React.useState(false);

  const addVariant = async () => {
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/variants`;
    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const newVariant = {
      name: newVariantName,
      artifact_id: artifactId,
      blocks: artifact?.blocks,
      properties: {
        role: newVariantSettings.role,
        industry: newVariantSettings.industry,
        audience_level: String(newVariantSettings.level),
      },
    };

    console.log('Adding variant: ', newVariant);

    await axios
      .post(url, newVariant, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        fetchArtifact();
      })
      .catch((error) => {
        enqueueSnackbar('Failed to add variant', { variant: 'error' });
        console.error('Error:', error);
      })
      .finally(() => {
        setAddVariantOpen(false);
        setNewVariantName('');
        setnewVariantSettings({});
      });
  };

  const deleteVariant = async (variantId) => {
    if (!variantId) {
      console.error('No variant ID provided.');
      return;
    }
    if (selectedVariantIndex === artifact?.variants.map((e) => e._id).indexOf(variantId)) {
      setSelectedVariantIndex(-1);
    }
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}/variants/${variantId}`;
    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };

    await axios
      .delete(url, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        fetchArtifact();
      })
      .catch((error) => {
        enqueueSnackbar('Failed to delete variant', { variant: 'error' });
        console.error('Error:', error);
      });
  };

  const [newVariantName, setNewVariantName] = React.useState('');
  const [newVariantSettings, setnewVariantSettings] = React.useState({
    // role: '',
    // industry: '',
    // level: '',
  });

  const [variantAnchorEl, setVariantAnchorEl] = React.useState(null);
  const [selectedVariantIndex, setSelectedVariantIndex] = React.useState(-1);
  const [contextMenuVariantId, setContextMenuVariantId] = React.useState(null);

  const [segment, setSegment] = React.useState('');
  const [product, setProduct] = React.useState('');

  const updateArtifactFEPs = async () => {
    const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactId}`;
    const accessToken = await getAccessTokenSilently();
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };
    const updatedArtifact = {
      frontEndPrompts: getFrontEndPrompts(),
    };

    await axios
      .put(url, updatedArtifact, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        enqueueSnackbar('Failed to update artifact', { variant: 'error' });
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (loadedSavedPrompts) {
      updateArtifactFEPs();
    }
  }, [topic, industry, readerRole, level, segment, product, selectedAssetIds]);

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{
          bgcolor: 'grey.200',
        }}
        display="flex"
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setActiveArtifactId(null)}
        >
          <ChevronLeft />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={() => setSelectedVariantIndex(-1)}
            color={'inherit'}
            underline={selectedVariantIndex >= 0 ? 'always' : 'none'}
            sx={{ cursor: 'pointer' }}
          >
            {artifact?.name}
          </Link>
          {artifact && selectedVariantIndex >= 0 && (
            <Typography>{artifact.variants[selectedVariantIndex].name}</Typography>
          )}
        </Breadcrumbs>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Button variant="contained" color="primary" onClick={() => setArtifactVersionListOpen(true)}>
          View Versions
        </Button>
      </Toolbar>
      <Box sx={{ flexGrow: 1, display: 'flex', padding: '10px', height: '95vh' }}>
        <Box
          minWidth="400px"
          sx={{
            border: '1px solid lightgray',
            padding: '10px',
            overflow: 'auto',
          }}
        >
          <div>
            <>
              <Card sx={{ marginBottom: '10px' }}>
                <CardHeader
                  title={'Topic'}
                  titleTypographyProps={{ fontWeight: 'medium', textAlign: 'left', variant: 'h7' }}
                ></CardHeader>
                <CardContent>
                  <TextField
                    multiline
                    rows={8}
                    value={topic}
                    label="Topic"
                    onChange={(event) => {
                      setTopic(event.target.value);
                    }}
                    sx={{ width: '100%' }}
                  ></TextField>
                </CardContent>
              </Card>

              <Card sx={{ marginBottom: '10px' }}>
                <CardHeader
                  title={'Audience'}
                  titleTypographyProps={{ fontWeight: 'medium', textAlign: 'left', variant: 'h7' }}
                ></CardHeader>

                <CardContent>
                  <Autocomplete
                    disablePortal
                    options={[
                      'Education',
                      'Financial Services',
                      'Manufacturing',
                      'Technology',
                      'Telecommunications',
                      'Travel',
                      'Transportation',
                    ]}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    value={industry}
                    onChange={(event, newValue) => setIndustry(newValue)}
                    renderInput={(params) => <TextField {...params} label="Industry" />}
                  />
                  <TextField
                    label="Role"
                    placeholder="What role does your reader have?"
                    value={readerRole}
                    onChange={(event) => setReaderRole(event.target.value)}
                    sx={{ width: '100%', marginBottom: '10px' }}
                  />
                  <Typography id="input-slider" textAlign={'left'} gutterBottom>
                    Audience Level: {level}
                  </Typography>
                  <Slider
                    defaultValue={100}
                    min={100}
                    max={400}
                    step={100}
                    value={level}
                    sx={{ width: '90%' }}
                    onChange={(event, newValue) => {
                      setLevel(newValue);
                    }}
                    marks={[
                      {
                        value: 100,
                        label: '100',
                      },
                      {
                        value: 200,
                        label: '200',
                      },
                      {
                        value: 300,
                        label: '300',
                      },
                      {
                        value: 400,
                        label: '400',
                      },
                    ]}
                  >
                    <SliderValueLabel value="100" />
                  </Slider>
                  <Autocomplete
                    disablePortal
                    options={['Enterprise', 'Mid-market', 'SMB']}
                    sx={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}
                    value={segment}
                    onChange={(event, newValue) => {
                      setSegment(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Segment" />}
                  />
                  <TextField
                    label="Product"
                    placeholder="What is the product?"
                    value={product}
                    onChange={(event) => {
                      setProduct(event.target.value);
                    }}
                    sx={{ width: '100%', marginBottom: '10px' }}
                  />
                </CardContent>
              </Card>

              {/* <Card sx={{ marginBottom: '10px' }}>
                <CardHeader
                  title={'Variants'}
                  titleTypographyProps={{ fontWeight: 'medium', textAlign: 'left', variant: 'h7' }}
                ></CardHeader>
                <CardContent>
                  <List>
                    <Divider />
                    {artifact && artifact.variants && artifact.variants.length > 0 ? (
                      artifact.variants.map((variant, index) => (
                        <div>
                          <ListItemButton
                            selected={selectedVariantIndex === index}
                            disableRipple
                            onClick={() => {
                              setSelectedVariantIndex(index);
                              console.log(artifact.variants[selectedVariantIndex]);
                            }}
                          >
                            <ListItemText primary={variant.name} />

                            <IconButton
                              edge="end"
                              onClick={(event) => {
                                event.stopPropagation();
                                setVariantAnchorEl(event.currentTarget);
                                setContextMenuVariantId(variant._id);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </ListItemButton>
                          <Divider />
                        </div>
                      ))
                    ) : (
                      <div>
                        <ListItem>
                          <ListItemText primary="No variants" />
                        </ListItem>
                        <Divider />
                      </div>
                    )}
                  </List>
                  <Button onClick={() => setAddVariantOpen(true)}>Add a Variant</Button>
                </CardContent>
              </Card>
              <Menu
                id="variant-menu"
                anchorEl={variantAnchorEl}
                open={Boolean(variantAnchorEl)}
                onClose={() => setVariantAnchorEl(null)}
              >
                <MenuItem onClick={() => setVariantAnchorEl(null)}>
                  <ListItemIcon>
                    <Download />
                  </ListItemIcon>
                  <Typography variant="inherit">Download</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setVariantAnchorEl(null);
                    deleteVariant(contextMenuVariantId);
                  }}
                >
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu> */}

              <Card sx={{ marginBottom: '10px' }}>
                <CardHeader
                  title={'Knowledge'}
                  titleTypographyProps={{ fontWeight: 'medium', textAlign: 'left', variant: 'h7' }}
                ></CardHeader>
                <CardContent>
                  {project && project.assets && (
                    <ArtifactAssetTable
                      assets={project.assets}
                      onSelectionChange={(assetIds) => setSelectedAssetIds(assetIds)}
                      selectedAssetIds={selectedAssetIds}
                    />
                  )}

                  <label htmlFor="raised-button-file">
                    <Button onClick={openAssetsDialog}>Add an Asset</Button>
                  </label>
                </CardContent>
              </Card>
            </>
          </div>
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {artifact && selectedVariantIndex >= 0 ? (
            <VariantGuidePane
              sx={{ height: '200px', flexBase: '200px' }}
              artifact={artifact}
              expandedPanel={expandedPanel}
              setExpandedPanel={setExpandedPanel}
              onGenerate={onGuideGenerate}
              onDownloadFile={downloadFile}
              regenerateInstructions={regenerateInstructions}
              setRegenerateInstructions={setRegenerateInstructions}
              regenerateAll={regenerateAll}
              variant={artifact.variants[selectedVariantIndex]}
              setSelectVariantIndex={setSelectedVariantIndex}
            />
          ) : (
            <ArtifactGuidePane
              sx={{ height: '200px', flexBase: '200px' }}
              artifact={artifact}
              expandedPanel={expandedPanel}
              setExpandedPanel={setExpandedPanel}
              onGenerate={onGuideGenerate}
              onDownloadFile={downloadFile}
              regenerateInstructions={regenerateInstructions}
              setRegenerateInstructions={setRegenerateInstructions}
              regenerateAll={regenerateAll}
            />
          )}
          <Box sx={{ flexGrow: 1, overflowY: 'scroll' }}>
            <Paper elevation={2}>
              {artifact && selectedVariantIndex >= 0
                ? artifact.variants[selectedVariantIndex]?.blocks?.map((block, index) => (
                    <BlockDisplay
                      index={index}
                      key={block.block_id}
                      block={block}
                      openRegenDialog={openRegenDialog}
                      generating={generating}
                    />
                  ))
                : artifact?.blocks?.map((block, index) => (
                    <BlockDisplay
                      index={index}
                      key={block.block_id}
                      block={block}
                      openRegenDialog={openRegenDialog}
                      generating={generating}
                    />
                  ))}
            </Paper>

            <RegenerateDialog
              type="chapter"
              open={regenDialogOpen}
              setRegenDialogOpen={setRegenDialogOpen}
              regenerateInstructions={regenerateInstructions}
              setRegenerateInstructions={setRegenerateInstructions}
              action={regenerateBlock}
            />

            <ArtifactVersionList
              open={artifactVersionListOpen}
              setOpen={setArtifactVersionListOpen}
              artifact={artifact}
              onChangedVersion={fetchArtifact}
            />

            {/* <Dialog open={addVariantOpen} fullWidth maxWidth="sm">
              <DialogTitle>Add a Variant</DialogTitle>
              <DialogContent sx={{ alignItems: 'center', display: 'flex', gap: '8px', flexDirection: 'column' }}>
                <TextField
                  label="Variant Name"
                  value={newVariantName}
                  onChange={(event) => setNewVariantName(event.target.value)}
                  sx={{ width: '100%', marginTop: '8px' }}
                />

                <TextField
                  label="Role"
                  placeholder="What role does your reader have?"
                  value={newVariantSettings.role}
                  onChange={(event) => setnewVariantSettings({ ...newVariantSettings, role: event.target.value })}
                  sx={{ width: '100%' }}
                />

                <Autocomplete
                  disablePortal
                  options={[
                    'Education',
                    'Financial Services',
                    'Manufacturing',
                    'Technology',
                    'Telecommunications',
                    'Travel',
                    'Transportation',
                  ]}
                  sx={{ width: '100%' }}
                  value={newVariantSettings.industry}
                  onChange={(event, newValue) => setnewVariantSettings({ ...newVariantSettings, industry: newValue })}
                  renderInput={(params) => <TextField {...params} label="Industry" />}
                />

                <Typography width={'100%'} id="input-slider" textAlign={'left'} gutterBottom>
                  Audience Level: {newVariantSettings.level}
                </Typography>
                <Slider
                  defaultValue={100}
                  min={100}
                  max={400}
                  step={100}
                  value={newVariantSettings.level}
                  sx={{ width: '90%' }}
                  onChange={(event, newValue) => {
                    setnewVariantSettings({ ...newVariantSettings, level: newValue });
                  }}
                  marks={[
                    {
                      value: 100,
                      label: '100',
                    },
                    {
                      value: 200,
                      label: '200',
                    },
                    {
                      value: 300,
                      label: '300',
                    },
                    {
                      value: 400,
                      label: '400',
                    },
                  ]}
                >
                  <SliderValueLabel value="100" />
                </Slider>
              </DialogContent>
              <DialogActions>
                <Button onClick={addVariant}>Add</Button>
                <Button
                  onClick={() => {
                    setAddVariantOpen(false);
                    setNewVariantName('');
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog> */}

            {project && (
              <AssetUploadDialog
                open={assetUploadDialogOpen}
                onClose={closeAssetsDialog}
                projectId={project._id}
                refreshAssets={refreshAssets}
              ></AssetUploadDialog>
            )}

            <Backdrop open={generating}>
              <Paper elevation={15} sx={{ padding: 7 }}>
                <Typography>{randomProgressPhrase}</Typography>

                <img src="acerca_spinner.gif" alt="Loading..." height="75px" />
              </Paper>
            </Backdrop>
          </Box>
        </Box>
      </Box>
    </>
  );
}
